import * as React from "react"
import type { RouteUpdateArgs } from "gatsby"

import scrollTo from 'gatsby-plugin-smoothscroll'

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs) => {
    if (document.location.search) {
        const id = document.location.search.substring(1);
        let elem = document.querySelector(`#${id}`);
        if (elem) {
            scrollTo(`#${id}`);
        }
    }
        
};